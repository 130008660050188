import React, { useState, useRef } from "react";
import Assets from "../../assets/images";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { postRequest } from "../../utills/requests";
import {
  getStyling,
  setEdit,
  setSideBar,
  submitRequest,
  updateTracker,
} from "../../store/slices/bodyStyling";
import { Circles } from "react-loader-spinner";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { setPageId } from "../../store/slices/loginSlice";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Cross, X } from "lucide-react";

function Topbar() {
  const dispatch = useDispatch();
  // const pages = useSelector((state) => state.bodyStyling.styling.pages);
  const pages = useSelector((state) => state.login.pages);
  const changeTracker = useSelector((state) => state.bodyStyling.changeTracker);
  const order = useSelector((state) => state.bodyStyling.homePageOrder);
  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );
  const loader = useSelector((state) => state.bodyStyling.submitLoader);
  const widgetsStyles = useSelector((state) => state.bodyStyling.widgetsStyles);
  const sideBarState = useSelector((state) => state.bodyStyling.sideBarState);
  const pageId = useSelector((state) => state.login.pageId);

  const ref = useRef(null);

  const navigate = useNavigate();

  const handleSubmit = async () => {
    const data = JSON.parse(JSON.stringify(order));
    data["universalStyles"] = universalStyles;
    data["widgetStyles"] = widgetsStyles;
    dispatch(submitRequest(true));
    try {
      const resp = await postRequest({
        endpoint: "/edit-current-styles",
        payload: data,
      });
      toast.success(resp.data.msg, { id: "changesMade" });
      dispatch(updateTracker(false));
      localStorage.removeItem("customId");
      localStorage.removeItem("widgetName");
      dispatch(
        setEdit({
        })
      );
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.remove("hide");
      sidebaroption2.classList.remove("hide");
      widgetMain.classList.remove("show");
      dispatch(setSideBar(false));
    } catch (err) {
      toast.error(
        err.response === undefined
          ? "Something went wrong"
          : err.response.data.message,
        { id: "updateErr" }
      );
    } finally {
      dispatch(submitRequest(false));
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  const goBack = () => {
    if (sideBarState && changeTracker) {
      dispatch(getStyling());
      dispatch(updateTracker(false));
    }
    const sidebaroption = document.querySelector(".sidebaroption");
    const sidebaroption2 = document.querySelector(".sidebaroption2");
    const widgetMain = document.querySelector(".custom-widgetMain");
    sidebaroption.classList.remove("hide");
    sidebaroption2.classList.remove("hide");
    widgetMain.classList.remove("show");
    localStorage.removeItem("customId");
    dispatch(setSideBar(false));
    dispatch(setEdit({ }));
    localStorage.removeItem("widgetName");
  };

  const [localPageId, setLocalPageId] = useState("");
  return (
    <>
      <div className="maintopbar">
        <div className="maintopbarInnerLeft">
          <div className="logomain">
            <img src={Assets.logo} alt="Logo Main" />
          </div>
        </div>
        <div className="maintopbarInnerRight">
          <div className="topbarrightcol topbarrightcol-1">
            <div className="topbarrightcolinner">
              <div
                className="backbtn"
                onClick={() => goBack()}
                style={{
                  cursor: sideBarState ? "pointer" : "unset",
                  opacity: sideBarState ? 1 : 0.2,
                }}
              >
                <img src={Assets.backarrow} alt="Back Arrow" />
                <span>Back</span>
              </div>
              <div className="pageDropdown">
                <span>page:</span>
                <select
                  value={pageId}
                  className="watchSelect"
                  onChange={(e) => {
                    if (changeTracker) {
                      setLocalPageId(e.target.value);
                      ref.current.open();
                      return;
                    } else {
                      if (e.target.value == 1) {
                        dispatch(setPageId("1"));
                        navigate("/home");
                      } else if (e.target.value == 2) {
                        navigate("/watch");
                        dispatch(setPageId("2"));
                      } else if (e.target.value == 3) {
                        navigate("/giving");
                        dispatch(setPageId("3"));
                      } else if (e.target.value == 4) {
                        navigate("/group");
                        dispatch(setPageId("4"));
                      } else if (e.target.value == 5) {
                        navigate("/team");
                        dispatch(setPageId("5"));
                      } else if (e.target.value == 6) {
                        navigate("/serve");
                        dispatch(setPageId("6"));
                      } else if (e.target.value == 7) {
                        navigate("/kids");
                        dispatch(setPageId("7"));
                      }
                    }

                    // close widget size side bar
                    const sidebaroption =
                      document.querySelector(".sidebaroption");
                    const sidebaroption2 =
                      document.querySelector(".sidebaroption2");
                    const widgetMain =
                      document.querySelector(".custom-widgetMain");
                    sidebaroption.classList.remove("hide");
                    sidebaroption2.classList.remove("hide");
                    widgetMain.classList.remove("show");
                    localStorage.removeItem("customId");
                    dispatch(setSideBar(false));
                    dispatch(setEdit({}));
                    localStorage.removeItem("widgetName");
                  }}
                >
                  {pages &&
                    pages.map((page) => {
                      return (
                        <option key={page.id} id={page.id} value={page.id}>
                          {page.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </div>
          <div className="topbarrightcol topbarrightcol-2">
            <div className="topbarrightcolinner">
              <ul>
                <li className="active">
                  <img src={Assets.laptop} alt="Laptop" />
                </li>
                <li>
                  <img src={Assets.smartphone} alt="Smart Phone" />
                </li>
              </ul>
            </div>
          </div>
          <div className="topbarrightcol topbarrightcol-3">
            <div className="topbarrightcolinner">
              <div className="backbtn undoBtn">
                <img src={Assets.undoico} alt="Undo Icon" />
                <span>Undo</span>
              </div>
              <div className="defaultButtons">
                {/* <a href="#" className="ligthButton">
                  Preview
                </a> */}
                <button onClick={handleLogout} className="ligthButton">
                  Logout
                </button>
                {/* <a onClick={handleSubmit} href="#" className="BlueButton">
                  Submit
                </a> */}

                <button
                  className="BlueButton"
                  onClick={handleSubmit}
                  disabled={!changeTracker}
                  style={{ opacity: !changeTracker ? 0.6 : 1 }}
                >
                  {loader ? (
                    <Circles
                      height="20"
                      width="50"
                      color="#4b6cb7"
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup
        lockScroll={true}
        contentStyle={{
          border: "unset",
          padding: 0,
          borderRadius: 10,
        }}
        ref={ref}
        modal={true}
        trigger={<div style={{ marginLeft: "unset" }} />}
        position="center center"
      >
        {(close) => (
          <div
            className="confirmation-dialog"
            style={{ backgroundColor: "black", borderRadius: 10, padding: 20 }}
          >
            <X
              onClick={close}
              color="grey"
              style={{
                position: "absolute",
                top: 4,
                right: 4,
                cursor: "pointer",
              }}
            />
            <p
              style={{
                fontSize: 20,
                color: "#ffffff",
                textAlign: "center",
                marginTop: 6,
                paddingInline: 10,
              }}
            >
              Current changes will be discarded?
              <br /> Would you like to save?
            </p>
            <div className="d-flex align-items-center justify-content-around">
              <button
                onClick={() => {
                  ref.current.close();
                  handleSubmit();
                }}
                style={{
                  fontSize: 20,
                  color: "grey",
                  outline: "unset",
                  backgroundColor: "transparent",
                  fontWeight: "bolder",
                }}
              >
                Yes
              </button>
              <button
                onClick={() => {
                  ref.current.close();
                  if (localPageId == 1) {
                    dispatch(setPageId("1"));
                    navigate("/home");
                  } else {
                    navigate("/watch");
                    dispatch(setPageId("2"));
                  }
                  dispatch(updateTracker(false));
                }}
                style={{
                  fontSize: 20,
                  color: "red",
                  outline: "unset",
                  backgroundColor: "transparent",
                  fontWeight: "bolder",
                }}
              >
                No, Discard
              </button>
            </div>
          </div>
        )}
      </Popup>
    </>
  );
}

export default Topbar;
