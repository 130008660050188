import React, { useCallback, useEffect, useState } from "react";
import "../../home/styles.css";
import Assets from "../../../assets/images";
import WidgetTool from "../../../components/Toolbars/WidgetToolbar";
import { useDispatch, useSelector } from "react-redux";
import {
  setEdit,
  setSideBar,
  setStopDrag,
  updateTracker,
} from "../../../store/slices/bodyStyling";
import TextToolbar from "../../../components/Toolbars/TextToolbar";
import FileDrop from "../../../components/Toolbars/FileDrop";
import ButtonToolbar from "../../../components/Toolbars/ButtonToolbar";
import { updateWigetsStyles } from "../../../store/slices/bodyStyling";
import { getGradient } from "../../../utills/getGradient";

const ConnectWidget = ({
  edithandleClick,
  itemStyles,
  height,
  width,
  widgetId,
  deleteWidget,
  x,
}) => {
  const dispatch = useDispatch();
  const editingMode = useSelector((state) => state.bodyStyling.isEdit);
  const universalStyles = useSelector(
    (state) => state.bodyStyling.universalStyles
  );
  const draggable = useSelector((state) => state.bodyStyling.isEdit);
  const { default_widget_colors, mainColor } = universalStyles;
  const [imagePosition, setImagePosition] = useState("100% 100%");
  const [imageRepeat, setImgRepeat] = useState("no-repeat");

  // console.log("STOP>", stopDrag);
  // console.log("EDIT>", editingMode);

  useEffect(() => {
    if (universalStyles.corners && defaultCorners == "true") {
      setBorder(universalStyles.corners);
    }
    if (universalStyles.font) {
      font.isDefaultFont == 1 &&
        setFont((prevFont) => ({
          ...prevFont,
          family: universalStyles.font,
        }));
      buttonFont.isDefaultFont == 1 &&
        setButtonFont((prevFont) => ({
          ...prevFont,
          family: universalStyles.font,
        }));
    }
  }, [universalStyles.corners, universalStyles.font]);

  const [isDefaultButton, setDefaultButton] = useState(0);
  const [buttonFont, setButtonFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "gilroymedium",
    size: 18,
    isDefaultFont: 1,
  });

  useEffect(() => {
    if (itemStyles) {
      if (
        itemStyles.background_color !== "0" &&
        itemStyles.background_color !== null
      ) {
        // console.log("COLOR IN>", itemStyles.background_color);
        try {
          const bgstyle = JSON.parse(itemStyles.background_color);
          setColor(bgstyle);
        } catch (error) {
          const bgstyle = itemStyles.background_color;
          setColor(bgstyle);
        }
      } else {
        if (itemStyles.background_image) {
          // console.log("IMG HERE>", itemStyles.background_image);
          setImage(itemStyles.background_image);
        }
      }

      setBtnTxt(itemStyles.button_text);
      setTitle(itemStyles.title);
      // setBtnBg(itemStyles.button_background_color);
      if (itemStyles.button_background_color) {
        try {
          const bgstyle = JSON.parse(itemStyles.button_background_color);
          setBtnBg(bgstyle);
        } catch (error) {
          const bgstyle = itemStyles.button_background_color;
          setBtnBg(bgstyle);
        }
      }

      setTxtColor(itemStyles.button_text_color);
      if (itemStyles.title_font_style) {
        try {
          const fontstyle = JSON.parse(itemStyles.title_font_style);
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
        } catch (error) {
          const fontstyle = itemStyles.title_font_style;
          setFont({
            ...fontstyle,
            color: itemStyles.title_color,
            family: itemStyles.title_font,
            size: itemStyles.title_font_size,
          });
        }
      }

      if (itemStyles.button_font_style) {
        try {
          const buttonStyle = JSON.parse(itemStyles.button_font_style);
          setButtonFont({
            ...buttonStyle,
            color: itemStyles.button_text_color,
            family: itemStyles.button_font,
            size: itemStyles.button_font_size,
          });
          setDefaultButton(buttonStyle.isDefaultButton);
        } catch (error) {
          const buttonStyle = itemStyles.button_font_style;
          setButtonFont({
            ...buttonStyle,
            color: itemStyles.button_text_color,
            family: itemStyles.button_font,
            size: itemStyles.button_font_size,
          });
          setDefaultButton(buttonStyle.isDefaultButton);
          console.error("Error parsing heading title font style:", error);
        }
      }
      // setImage(itemStyles.background_image);
      setLink(itemStyles.widget_link);
      setBtnLink(itemStyles.button_link);
      setBorder(itemStyles.border);
      setDefaultCorners(itemStyles.defaultCorners);
      setDefault(itemStyles.is_default);
      setImagePosition(itemStyles.image_position);
      setImgRepeat(itemStyles.image_repeat);
    }
  }, [itemStyles]);

  const [isDefault, setDefault] = useState(0);

  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditText, setEditText] = useState(false);
  const [editBtn, setEditBtn] = useState(false);
  const [image, setImage] = useState("");
  const [imgFile, setImgFile] = useState("");

  const [fileBox, showFileBox] = useState(false);
  const [color, setColor] = useState(false);
  const [title, setTitle] = useState("");
  const [btnBg, setBtnBg] = useState("");
  const [btnTxt, setBtnTxt] = useState("");
  const [txtColor, setTxtColor] = useState("");
  const [link, setLink] = useState("");
  const [btnlink, setBtnLink] = useState("");
  const [font, setFont] = useState({
    color: "#ffffff",
    isBold: false,
    isItalic: false,
    isUnderlined: false,
    family: "frontage",
    size: 34,
    isDefaultFont: 1,
  });
  const [border, setBorder] = useState("");
  const [defaultCorners, setDefaultCorners] = useState("true");

  const [colorFromPicker, setColorFromPicker] = useState(false);
  useEffect(() => {
    if (
      colorFromPicker &&
      (image !== "0" || image !== null || image !== false)
    ) {
      setImage(false);
      setImgFile("");
    }
  }, [colorFromPicker]);

  useEffect(() => {
    fileBox && showFileBox(false);
    if (imgFile !== "") {
      setColor("");
    }
  }, [imgFile]);

  //COUNT for color useeffect check
  const [count, setCount] = useState(0);
  // useEffect(() => {
  //   count > 1 && setImage(false);
  //   setCount(count + 1);
  // }, [color]);

  useEffect(() => {
    if (editingMode.widgtId !== widgetId) {
      showFileBox(false);
      setEditText(false);
    }
  }, [editingMode.widgtId]);

  const handleEditClick = (e) => {
    dispatch(updateTracker(true))
    if (editingMode.widgtId !== widgetId && draggable.isEditMode == false) {
      localStorage.removeItem("customId");
      localStorage.removeItem("widgetName");
      dispatch(setEdit({}));
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.remove("hide");
      sidebaroption2.classList.remove("hide");
      widgetMain.classList.remove("show");
      dispatch(setSideBar(false));
      return;
    }

    if (editingMode.widgtId !== widgetId) {
      dispatch(setEdit({ isEditMode: false, widgtId: widgetId }));
      setIsEditMode(true);
      setEditText(false);
      localStorage.setItem("customId", widgetId);
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.add("hide");
      sidebaroption2.classList.add("hide");
      widgetMain.classList.add("show");
      // dispatch(setSideBar(true));
    } else {
      setIsEditMode(false);
      localStorage.removeItem("customId");
      localStorage.removeItem("widgetName");
      dispatch(setEdit({}));
      const sidebaroption = document.querySelector(".sidebaroption");
      const sidebaroption2 = document.querySelector(".sidebaroption2");
      const widgetMain = document.querySelector(".custom-widgetMain");
      sidebaroption.classList.remove("hide");
      sidebaroption2.classList.remove("hide");
      widgetMain.classList.remove("show");
      // dispatch(setSideBar(true));
    }
    setEditBtn(false);
    e.stopPropagation();
  };

  let widgetData = { widget_id: widgetId.toString() };
  useEffect(() => {
    // count > 0 && dispatch(updateTracker(true));
    // widgetData["background_image"] = image;
    widgetData["background_image"] = imgFile ? imgFile : null;
    widgetData["background_color"] = color;
    widgetData["border"] = border ? border : null;
    widgetData["defaultCorners"] = defaultCorners;
    widgetData["is_default"] = isDefault;
    widgetData["image_position"] = imagePosition;
    widgetData["image_repeat"] = imageRepeat;
    widgetData["text1"] = null;
    widgetData["heading1"] = null;
    widgetData["paragraph1"] = null;
    widgetData["button1"] = null;

    widgetData["button_background_color"] = btnBg;
    widgetData["button_text"] = btnTxt;
    widgetData["button_text_color"] = txtColor;
    widgetData["button_link"] = btnlink;
    widgetData["button_font_style"] = {
      isBold: buttonFont.isBold,
      isUnderlined: buttonFont.isUnderlined,
      isItalic: buttonFont.isItalic,
      isDefaultFont: buttonFont.isDefaultFont,
      isDefaultButton: isDefaultButton,
    };
    widgetData["button_font_size"] = buttonFont.size ? buttonFont.size : null;
    widgetData["button_font"] = buttonFont.family ? buttonFont.family : null;

    widgetData["text_alignmnet"] = null;
    widgetData["title"] = title;
    widgetData["title_color"] = font.color;
    widgetData["title_font"] = font.family;
    widgetData["title_font_size"] = font.size;
    widgetData["title_font_style"] = {
      isBold: font.isBold,
      isUnderlined: font.isUnderlined,
      isItalic: font.isItalic,
      isDefaultFont: font.isDefaultFont,
    };

    widgetData["date_font"] = null;
    widgetData["date_font_color"] = null;
    widgetData["date_font_size"] = null;
    widgetData["date_font_style"] = null;

    widgetData["description"] = null;
    widgetData["description_color"] = null;
    widgetData["description_font"] = null;
    widgetData["description_font_size"] = null;
    widgetData["description_font_style"] = null;

    widgetData["widget_link"] = link;

    widgetData["button_alignmnet"] = null;

    widgetData["heading_alignmnet"] = null;
    widgetData["heading_title"] = null;
    widgetData["heading_title_color"] = null;
    widgetData["heading_title_font"] = null;
    widgetData["heading_title_font_size"] = null;
    widgetData["heading_title_font_style"] = null;

    widgetData["paragraph_alignmnet"] = null;
    widgetData["paragraph_title"] = null;
    widgetData["paragraph_title_color"] = null;
    widgetData["paragraph_title_font"] = null;
    widgetData["paragraph_title_font_size"] = null;
    widgetData["paragraph_title_font_style"] = null;

    widgetData["text_height"] = null;
    widgetData["text_width"] = null;
    widgetData["heading_height"] = null;
    widgetData["heading_width"] = null;
    widgetData["paragraph_height"] = null;
    widgetData["paragraph_width"] = null;
    // coords
    widgetData["text_x"] = null;
    widgetData["text_y"] = null;
    widgetData["heading_x"] = null;
    widgetData["heading_y"] = null;
    widgetData["paragraph_x"] = null;
    widgetData["paragraph_y"] = null;
    widgetData["button_x"] = null;
    widgetData["button_y"] = null;
    // col2
    widgetData["background_image2"] = null;
    widgetData["background_color2"] = null;
    widgetData["is_default2"] = null;
    widgetData["image_position2"] = null;
    widgetData["image_repeat2"] = null;
    widgetData["text2"] = null;
    widgetData["heading2"] = null;
    widgetData["paragraph2"] = null;
    widgetData["button2"] = null;
    // col3
    widgetData["background_image3"] = null;
    widgetData["background_color3"] = null;
    widgetData["is_default3"] = null;
    widgetData["image_position3"] = null;
    widgetData["image_repeat3"] = null;
    widgetData["text3"] = null;
    widgetData["heading3"] = null;
    widgetData["paragraph3"] = null;
    widgetData["button3"] = null;
    //accordion
    widgetData["accordion1"] = null;
    widgetData["accordion2"] = null;
    widgetData["accordion3"] = null;
    // Accordion group coords
    widgetData["accordionCoords1"] = null;
    widgetData["accordionCoords2"] = null;
    widgetData["accordionCoords3"] = null;

    widgetData["text_direction"] = null;
    widgetData["paragraph_direction"] = null;
    widgetData["heading_direction"] = null;

    widgetData["latitude"] = null;
    widgetData["longitude"] = null;
    widgetData["place_address"] = null;

    widgetData["button_xy_percentage"] = null;
    widgetData["text_xy_percentage"] = null;
    widgetData["heading_xy_percentage"] = null;
    widgetData["paragraph_xy_percentage"] = null;
    dispatch(updateWigetsStyles(widgetData));
  }, [
    image,
    color,
    btnBg,
    font,
    title,
    txtColor,
    btnTxt,
    link,
    btnlink,
    border,
    defaultCorners,
    isDefault,
    buttonFont,
    isDefaultButton,
    imagePosition,
    imageRepeat,
    imgFile,
  ]);

  // Hover Edit
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => setTimeout(() => setIsHovered(true), 300);
  const handleMouseLeave = () => setIsHovered(false);

  const commonStyles = {
    borderRadius:
      border === "round"
        ? 20
        : border === "sharp"
        ? 0
        : universalStyles.corners === "round"
        ? 20
        : 0,
  };

  const backgroundStyles = (
    image,
    default_widget_colors,
    mainColor,
    color,
    imageRepeat,
    imagePosition,
    imgFile
  ) => {
    if ((image == "0" || image == null || image == false) && imgFile == "") {
      return {
        background: `${
          isDefault && default_widget_colors.custom
            ? default_widget_colors.custom.is_default === "0"
              ? default_widget_colors.custom.color
              : default_widget_colors.custom.is_default === "1"
              ? mainColor
              : getGradient(color)
            : getGradient(color)
        }`,
      };
    } else {
      return {
        backgroundImage: imgFile
          ? `url(${imgFile}`
          : `url(${process.env.REACT_APP_BASE_URL}${image}`,
        backgroundPosition: "center",
        backgroundRepeat: imageRepeat,
        backgroundSize: imagePosition,
      };
    }
  };

  const styles = {
    ...commonStyles,
    ...backgroundStyles(
      image,
      default_widget_colors,
      mainColor,
      color,
      imageRepeat,
      imagePosition,
      imgFile
    ),
  };

  return (
    <div
      onClick={(e) => {
        if (editingMode.widgtId == widgetId) {
          setEditText(false);
          setEditBtn(false);
          e.stopPropagation();
        }
      }}
      className={`bluegradientmain ${
        editingMode.widgtId == widgetId ? "edit-mode" : ""
      }`}
      style={styles}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => {
        setTimeout(() => {
          editingMode.widgtId !== widgetId && handleMouseLeave();
        }, 300);
      }}
    >
      {/* {editingMode.widgtId == widgetId && ( */}
      <div
        className={`editdeletebtn  hover-div ${isHovered ? "hovered" : ""}`}
        onMouseEnter={() => {
          dispatch(setStopDrag("yes"));
        }}
        onMouseLeave={() => {
          if (editingMode.widgtId !== widgetId) {
            dispatch(setStopDrag("no"));
          }
        }}
      >
        <div
          className="deletebtn"
          onClick={(e) => {
            if (
              editingMode.widgtId !== widgetId &&
              draggable.isEditMode == false
            ) {
              localStorage.removeItem("customId");
              localStorage.removeItem("widgetName");
              dispatch(setEdit({}));
              const sidebaroption = document.querySelector(".sidebaroption");
              const sidebaroption2 = document.querySelector(".sidebaroption2");
              const widgetMain = document.querySelector(".custom-widgetMain");
              sidebaroption.classList.remove("hide");
              sidebaroption2.classList.remove("hide");
              widgetMain.classList.remove("show");
              dispatch(setSideBar(false));
            } else {
              deleteWidget(widgetId);
            }
          }}
        >
          <img src={Assets.deletebtn} alt="Delete Image" />
        </div>
        <div className="editbtn" onClick={handleEditClick}>
          <img src={Assets.editicon} alt="Edit Image" />
        </div>
      </div>
      {/* )} */}
      <div
        style={{
          pointerEvents: editingMode.widgtId !== widgetId ? "none" : "",
        }}
        className="d-flex align-items-end"
      >
        <span
          onBlur={(e) => setTitle(e.target.innerText)}
          onFocus={(e) => {
            setEditText(true);
          }}
          onClick={(e) => e.stopPropagation()}
          suppressContentEditableWarning
          data-placeholder="text..."
          contentEditable
          role="textbox"
          className={`connectTextarea ${isEditText ? "editText" : ""}  `}
          placeholder="type here "
          disabled={editingMode.widgtId !== widgetId}
          style={{
            fontSize: Number(font.size),
            color: font.color ? font.color : "white",
            textDecorationLine: font.isUnderlined ? "underline" : "none",
            fontWeight: font.isBold ? "bold" : "normal",
            fontStyle: font.isItalic ? "italic" : "normal",
            fontFamily:
              font.family === "default"
                ? universalStyles.font
                : font.family
                ? font.family
                : "sans-serif",
            padding: 0,
          }}
        >
          {title}
        </span>
      </div>
      <div
        // onClick={() => console.log("CLicked")}
        className="welcomForm give-form"
      >
        <form
          style={{
            // pointerEvents: editingMode.widgtId !== widgetId ? "none" : "",
            pointerEvents: "none",
          }}
        >
          {/* <div className="formField">
            <input disabled type="text" placeholder="How Many Are Watching?" />
          </div> */}
          <h6 style={{color:'#fff',fontWeight:'bold'}} >Prayer Request</h6>
          <div className="formField">
            <input disabled type="text" placeholder="Please enter your prayer request" />
          </div>
        </form>
      </div>
      <div
        className="d-flex align-items-end"
        style={{
          justifyContent: height == 8 ? "center" : "flex-end",
        }}
      >
        <div
          onClick={(e) => {
            setEditBtn(true);
            e.stopPropagation();
          }}
          className="submit-btn-give"
        >
          <input
            style={{
              // backgroundColor: btnBg,
              background: getGradient(btnBg),
              color: txtColor,
              fontSize: Number(buttonFont.size),
              fontFamily:
                buttonFont.family === "default"
                  ? universalStyles.font
                  : buttonFont.family
                  ? buttonFont.family
                  : "sans-serif",
              fontStyle: buttonFont.isItalic ? "italic" : "normal",
              textDecorationLine: buttonFont.isUnderlined
                ? "underline"
                : "none",
              fontWeight: buttonFont.isBold ? "bold" : "normal",
            }}
            type="submit"
            value={btnTxt ? btnTxt : "Send"}
          />
        </div>
      </div>
      {editingMode.widgtId == widgetId && !isEditText && (
        <WidgetTool
          isDefault={isDefault}
          setDefault={setDefault}
          color={color}
          setColor={setColor}
          showFileBox={showFileBox}
          fileBox={fileBox}
          link={link}
          setLink={setLink}
          styles={{
            top: height == 3 ? "-20%" : height == 2 ? "-30%" : "-10%",
            width: "auto",
            left: height == 6 ? "30%" : "unset",
          }}
          imagePosition={imagePosition}
          setImagePosition={setImagePosition}
          setColorFromPicker={setColorFromPicker}
          colorFromPicker={colorFromPicker}
          // imageRepeat={imageRepeat}
          // setImgRepeat={setImgRepeat}
        />
      )}
      {isEditText && (
        <TextToolbar
          font={font}
          setFont={setFont}
          styles={{
            // top: height == 8 ? "-5%" : "-10%",
            top:
              height == 3
                ? "-20%"
                : height == 2
                ? "-30%"
                : height == 6
                ? "-10%"
                : "-6%",
          }}
        />
      )}
      {editingMode.widgtId == widgetId && fileBox && (
        <FileDrop
          setImage={setImage}
          setImgFile={setImgFile}
          borderRadius={border === "round" ? 20 : border === "sharp" ? 0 : null}
          height={height}
          width={width}
        />
      )}
      {editingMode.widgtId == widgetId && editBtn && (
        <ButtonToolbar
          btnBg={btnBg}
          btnTxt={btnTxt}
          txtColor={txtColor}
          setTxtColor={setTxtColor}
          setBtnTxt={setBtnTxt}
          setBtnBg={setBtnBg}
          btnLink={btnlink}
          setBtnLink={setBtnLink}
          styles={{
            // bottom: "-12%",
            bottom: height == 6 ? "0%" : height == 2 ? "-40%" : "-25%",
            width: "auto",
            right: x == 1 ? "2%" : "unset",
            left: x == 0 && height == 6 ? "30%" : x == 0 ? "2%" : "unset",
          }}
          setDefault={setDefaultButton}
          isDefault={isDefaultButton}
          font={buttonFont}
          setFont={setButtonFont}
        />
      )}
    </div>
  );
};

export default ConnectWidget;
